import React from "react";
import styled from "styled-components";
import Slider from './Caroussel'
import Sc1 from "./sc1";
import Sc2 from "./Sc2";

export default function Home() {
    return(
        <>
        <Slider />
        <Sc1 />
        <Sc2 />
        </>
    )
}