import React from "react";
import "./Privacy.css"; // Make sure to create this CSS file for custom styling.

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>Last updated: 21st May 2024</p>

      <section>
        <h2>Introduction</h2>
        <p>
          Best Online Guns Store is part of Friday Media Group Ltd and is a
          classified ads marketplace established to accurately match item
          sellers and service providers with a relevant buying audience.
        </p>
        <p>
          When you submit information to Best Online Guns Store, your
          information will also be shared with other relevant marketplace sites
          that form part of the Friday Media Group portfolio. Any such sites
          will also use your information in accordance with this Privacy Policy.
          Best Online Guns Store will remain responsible for the management and
          security of jointly used Personal Information that you submit to Best
          Online Guns Store.
        </p>
      </section>

      <section>
        <h2>How do we collect information from you?</h2>
        <p>
          We collect and use your Personal Information in accordance with this
          Privacy Policy.
        </p>
        <p>
          The majority of the Personal Information you provide to us freely when
          you submit to us your advertisement copy or enquire about an item or
          service via the website. If there is any information you would prefer
          us not to have, please omit this from any submissions sent to us. Some
          personal data is necessary for us to create an account for you, so in
          the instance where you do not want to share this Personal Information,
          we will be unable to provide our services to you.
        </p>
        <p>
          Some information we collect and store, whilst voluntarily given by
          yourself, may have been unintentional, such as information divulged
          through the use of search tools and content you interact with.
        </p>
        <p>Other sources we use also include:</p>
        <ul>
          <li>Our group websites</li>
          <li>
            The software applications made available by us for use on or through
            computers and mobile devices
          </li>
          <li>Publicly available information</li>
          <li>
            Cookies (see our Cookie Policy for more information on how we use
            Cookies)
          </li>
          <li>Other tools and applications</li>
        </ul>
      </section>

      <section>
        <h2>What Personal Information do we collect?</h2>
        <p>
          Personal Information we collect and store about you may include the
          following:
        </p>
        <ul>
          <li>
            General identification and contact information: your name; address;
            email; IP address; telephone details; gender; marital status; family
            status; date and place of birth; physical attributes including
            photos
          </li>
          <li>
            Other sensitive information: political opinions, racial or ethnic
            origin, trade union membership, religious beliefs and criminal
            record.
          </li>
          <li>
            Information enabling us to provide products and services: age,
            location, whether or not you hold a driving licence
          </li>
          <li>
            Information from Apps: User generated content submitted through
            contributing to online forums, commenting on blog posts, completing
            onsite forms or emailing any of our internal services teams
          </li>
          <li>
            Professional history information available publicly: educational
            background; employment history; skills and experience; professional
            licenses and affiliations; educational and professional
            qualifications
          </li>
          <li>
            Marketing preferences: defined when entering a competition or prize
            draw, submitting a survey response or other sales/marketing
            promotional activity
          </li>
          <li>
            Statistical information: aggregate statistical information about
            site visitors and users for internal use and for other lawful
            purposes such as salary comparison services. Where we utilise such
            information, we will do so using an anonymous format and not include
            any Personal Information.
          </li>
        </ul>
      </section>

      <section>
        <h2>
          What legal basis do we rely on to process your Personal Information?
        </h2>
        <p>
          We rely on various legal bases to process your Personal Information:
        </p>
        <ul>
          <li>
            <strong>Consent:</strong> In some cases, Best Online Guns Store
            processes your data with your consent. For example, we rely on
            consent when linking your account to your Social Network account.
            You have the right to withdraw consent at any time. Where consent is
            the only legal basis for processing, we will cease to process data
            after consent is withdrawn.
          </li>
          <li>
            <strong>Processing required to fulfil a contract:</strong> We may
            process your data when we need to do this to fulfil a contract with
            you, such as to send your online enquiry to an item seller or
            service provider you’ve initiated a conversation with.
          </li>
          <li>
            <strong>Legitimate Interest:</strong> We can also process your data
            when it is deemed our legitimate interest to do so and when these
            interests are not overridden by your data protection rights.
          </li>
        </ul>
      </section>

      <section>
        <h2>Who do we share your Personal Information with?</h2>
        <p>
          Best Online Guns Store may make Personal Information available to the
          following:
        </p>
        <ul>
          <li>
            <strong>Friday Media Group Ltd companies:</strong> Where group
            companies have access to your personal data, they will use it only
            for the purposes set out in this Privacy Policy. Best Online Guns
            Store will remain responsible for the management and security of
            jointly used Personal Information. Access to Personal Information
            within Best Online Guns Store, and our group companies, is
            restricted to those individuals who have a need to access the
            information for legitimate business purposes.
          </li>
          <li>
            <strong>Aggregators and other agents:</strong> We may make Personal
            Information available to third parties such as aggregators and other
            intermediaries and agents and other carefully selected business
            partners.
          </li>
          <li>
            <strong>Our service providers:</strong> This includes, but is not
            limited to, external third-party service providers such as
            accountants and auditors, IT systems, support and hosting service
            providers, market research and analysis service providers, data
            storage and cloud providers, and other outsourced providers that
            assist us in carrying out business requirements.
          </li>
          <li>
            <strong>
              Governmental authorities and third parties involved in court
              action:
            </strong>{" "}
            Best Online Guns Store may share Personal Information with
            governmental or other public authorities and third-party civil legal
            process participants and their accountants, auditors, lawyers, and
            other advisors and representatives as we believe to be necessary or
            appropriate.
          </li>
          <li>
            <strong>Other third parties:</strong> Where appropriate, we may
            occasionally share Personal Information with other third parties.
            This will always be done under contract and in accordance with your
            instructions.
          </li>
        </ul>
      </section>

      <section>
        <h2>How we use Personal Information?</h2>
        <ul>
          <li>
            Provide marketing information to you (including information about
            other products and services offered by selected third-party
            partners) in accordance with preferences you have previously
            provided. You can always change these at My Account.
          </li>
          <li>
            Carry out market research and analysis, including satisfaction
            surveys.
          </li>
          <li>
            Allow you to participate in contests, prize draws, and similar
            promotions.
          </li>
          <li>Enable social media sharing functionality.</li>
          <li>
            Personalise your experience on Best Online Guns Store by presenting
            information and advertisements tailored to you.
          </li>
          <li>
            Manage our infrastructure and business operations, and comply with
            internal policies and procedures.
          </li>
          <li>Comply with applicable laws and regulatory obligations.</li>
          <li>
            Establish and defend legal rights; protect our operations or those
            of any of our group companies or customers (you).
          </li>
          <li>
            Resolve complaints, and handle requests for data access or
            correction.
          </li>
          <li>
            Communicate with you as part of our business and distribute
            important information regarding changes to our policies.
          </li>
        </ul>
      </section>

      <section>
        <h2>How can you manage or delete Personal Information?</h2>
        <p>
          Below are the ways in which you can take control of how we use your
          Personal Information:
        </p>
        <ul>
          <li>
            When you register or send an enquiry through Best Online Guns Store,
            you will create an account on the site, against which we will store
            your basic details such as name, sex, and location. On future
            visits, when logging in to your 'My Account' area, it is protected
            by your password and may only be accessed by you. You can manage the
            content and information in your Account at any time, by logging in
            to your "My Account".
          </li>
          <li>
            When placing an item/product or service advertisement with Best
            Online Guns Store, you can select whether or not to expose your
            telephone number as one of the contact methods you can be reached
            via. The default setting is for your number to be shown.
          </li>
          <li>
            You can decide to update your preferences or unsubscribe from any
            marketing or promotional information that we may send to you by
            using the methods provided.
          </li>
          <li>
            If you would like to permanently delete your account and all
            information held on you, you can send an email to our Data
            Protection Officer (DPO) with the subject line "Permanently delete
            my information and account".
          </li>
        </ul>
      </section>

      <section>
        <h2>Security</h2>
        <p>
          Best Online Guns Store is committed to keeping your Personal
          Information safe and secure. We have put in place appropriate
          technical and organisational controls to safeguard the information we
          collect.
        </p>
        <p>
          To protect the privacy of any Personal Information you may have
          provided, we will take reasonable precautions, including technical and
          organisational measures, to protect that data. We regularly review our
          security systems to ensure your data remains protected and secure.
        </p>
      </section>

      <section>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may amend this Privacy Policy from time to time to keep it up to
          date with legal requirements and the way we operate our business.
          Please regularly check this page for the latest version of this
          Privacy Policy. If we make any significant changes, we will take
          appropriate steps to bring them to your attention.
        </p>
      </section>

      <section>
        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or how
          we handle your Personal Information, please contact our Data
          Protection Officer at:{" "}
          <a href="mailto:privacy@bestonlinegunsstore.com">
            privacy@bestonlinegunsstore.com
          </a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
